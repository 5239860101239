const englishContent = {
  General: {
    home: "Home",
    delete:"Delete",
    deleteAll:"Delete All",
    addToCart:"Add to Cart",
    addToList:"Add to List",
    chain: "Your supply chain, unchained",
    viewAll: "View All",
    clearButton: "Clear",
    logIn: "Log In",
    daysAndDelivery: "days + delivery",
    RequestIt: "Request it!",
    RequestProduct: "Request It",
    SignIn: "Sign In",
    SignUp: "Sign up",
    terms: "Agree with",
    enterQTY: "enter item quantity",
    increaseQTY: "increase item quantity",
    readMore: "Read More",
    decreaseQTY: "decrease item quantity",
    viewDetails: "View Details",
    deliveredPrice: "Delivered price:",
    pricePerCase: "Price/Case: ",
    Messages: {
      signToShowInformation:
        "Please sign-in or sign up to show additional information",
      signInOrSignUp: "Please sign-in or sign up to unlock wholesale pricing.",
      SignUptoUnlock: "Sign up to unlock wholesale pricing.",
      CompleteProfile: "Complete profile to unlock wholesale pricing",
      signInToContinue: "Sign in to continue",
      VerifyEmail: "Verify email to unlock wholesale pricing",
      areYouSureToVerifyEmail: "Are you sure you want to verify your email?",
      pleaseCompleteProfile:
        "Please complete your profile to see additional information!",
      emailSentToValidateYourAddress:
        "An e-mail has been sent in order to validate your address! ",
      NoPricesAvailable: "No prices available for this product",
      SomethingWrongTryAgain: "An error has occurred, please try again later.",
      alertMessage:
        "Don't forget to validate your email address before placing your first order.",
      saveProductsMsg:
        "Save even more by adding another product(s) from this vendor!",
      DueToInactivityMessage:
        "Due to inactivity, please refresh and log back in.",
    },
  },
  Menu: {
    shopByBrand: "Shop by Brand",
    buildMixedPallet: "Build a Mixed Pallet"
  },
  RestorePassword: {
    createNewPassword: "Create New Password",
    create: "Create",
    backTo: "Back to",
    signIn: "Sign in",
  },
  Home: {
    articlesTitle: "How to store Baking Supplies and Baked Goods",
  },
  PLP: {
    deliveredPrice: "Delivered price/Case",
    maxUnitPrice: "Maximum Uint Price: ",
    minUnitPrice: "Minimum Unit Price: ",
    maxDeliveredPrice: "Maximum Delivered Price: ",
    minDeliveredPrice: "Minimum Delivered Price:",
    sponsored: "Sponsored",
    bonusesAndExclusives: "Bonuses & Exclusives",
    promoText: "off",
    allProducts: "All products",
    SearchResult: "Search result",
    PromotionTitle: "Quaker Chewy Chocolatey Chip",
    ItemsPerPage: " Items per page",
    filters: "Filters",
    CannotFindProduct: "Can’t find a product?",
    SEO: {
      title: "E Pallet -",
      description: "Product List Page -",
    },
    CategoryBanner: {
      welcomeMessage: "Welcome to",
      brandDescription:
        "The perfect addition to your pantry, food service menu and mealtime routine.",
      brandSubDescription:
        "Made with only the finest ingredients and crafted to provide both flavor and nutrition.",
    },
    noProductsAvailable: "Sorry, no products were found.",
    doYouSeeWhatYouWant: "Don’t see what you want?",
    RequestForm: {
      sendRequest: "Send Request",
      ePalletDefinition:
        "ePallet is business to business only. Please be prepared to provide a valid reseller's permit (business license) or tax-exempt certificate (for a non-profit organization) when making a purchase.",
      requestSent: "Your request has been sent!",
    },
  },
  Brand: {
    CannotFindBrand: "Can’t find a Brand?",
  },
  HelpCenter: {
    backToHelpCenter: "Back to Help Center",
    searchResults: "Search Results",
    resultsFor: "Results found for",
    buyerTitle: "Buyer",
    vendorTitle: "Vendor",
    FAQSTitle: "FAQS",
    contactUsTitle: "Contact Us",
    helpful: "Helpful",
    yesAnswer: "Yes",
    noAnswer: "No",
  },
  ContactUs: {
    sendButton: "Send",
  },
  InPress: {
    readMoreDetails: "Read",
  },
  Testimonials: {
    page: "Page",
    of: "of",
    itemsPerPage: "Items per page",
  },
  checkout: {
    addAccount: "Add Account",
    enterValidAmount:
      "The value entered is greater than the available rewards.",
    hasMoreThanTwoDecimalDigits:
      "Redeem Amount should have 2 or fewer decimal digits.",
    enterIntegerRewards: "Enter integer number please!",
    redeem_rewardsAddedSuccessfully: "Haggle Rewards added successfully!",
    redeem_rewardsRemovedSuccessfully: "Haggle Rewards removed successfully!",
    enterPositiveRewards: "Please enter a positive number.",
    enterValidRewards: "Enter valid amount please!",
    enterNonZeroRewards: "Redeem Amount should be large than 0.",
    readMore: "Learn More",
    haggleRewards: "Unlock greater earnings with Haggle Rewards.",
    removeHaggleConfirmationText:
      "Are you sure you want to remove Haggle Rewards?",
    applyCoupon: "Apply coupon code",
    addCoupon: "Add coupon code",
    amountPlaceHolder: "Enter the amount",
    currentlyHave: "You currently have",
    haggle: "Haggle Rewards",
    billingAddress: "Billing Address",
    changeButton: "Change",
    paymentType: "Payment Type",
    creditCard: "Credit card",
    achPayments: "ACH Payments",
    achDirectDebit: "ACH Direct Debit",
    creditLine: "Credit Line",
    applyForCreditLine: "Apply for credit line",
    okButton: "Ok",
    cardBalance: "Credit Balance",
    stars: "**** **** ****",
    purchaseTitle: "Purchase Order",
    notesTitle: "Notes",
    shippingTitle: "Shipping Address",
    checkoutTitle: "Checkout",
    placeOrder: "Place Order",
    cancelButton: "Cancel",
    verifyEmail: "Verify Email",
    sendVerificationEmail: "Send verification email",
    verifyEmailModalText: "Please verify your email before placing your order",
    completeProfile: "Complete Profile",
    completeNow: "Complete now",
    completeProfileModalText:
      "Please complete your profile before placing your order",
    selectedCardDeleted:
      "This card is no longer available, your cards are updated.",
    selectedAddressDeleted:
      "This shipping address is no longer available, your addresses are updated.",
    applyHaggle:"Apply Haggle dollars to items of your choice",
    applied: "Applied",
    paymentDrawer: {
      changePaymentCreditCard: "Change Payment Credit Card",
      changeACHPayment: "Change ACH Payment",
      changeACHDirectDebit: "Change ACH Direct Debit",
      changeCreditLine: "Change Credit Line",
    },
    SEO: {
      title: "E Pallet - Checkout",
      description: "Checkout",
    },
  },
  OrderSummary: {
    thisOrder: "on this order",
    savingMsg: "You are saving",
    applied_haggle_rewards: "Applied Haggle Rewards",
    orderSummaryTitle: "Order Summary",
    itemsTotal: "Items total",
    insurance: "Insurance",
    delivery: "Delivery",
    tax: "Tax",
    achDiscount: "ACH discount",
    couponDiscount: "Coupon discount",
    orderTotal: "Order total",
    quantity: "Quantity",
  },
  PDP: {
    minShelf: "Minimum Shelf Life Upon Arrival",
    beEarning: "You could be earning",
    shelfLife:
      "Effective from the date of manufacture; not guaranteed upon delivery.",
    orderWithHaggle: "on this order with",
    haggle: "Earn rewards and more with Haggle.",
    specSheet: "Spec Sheet",
    requestAccess: "Request Access",
    redemptionText: "will be added in your cart",
    totalCase: "Total cases/pallet:",
    soldInStack: "Sold in stack. Minimum 2 pallets per stack.",
    outOfStock: "Currently out of stock",
    prototypeProduct: "Contact us for current availability",
    palletOutOfStock:
      "Limited inventory available. Click Build a Mixed Pallet button to purchase. ",
    AccessRequested:
      "You have requested access to this file. Please wait for vendor response",
    RestrictedInfo:
      "Vendor has restricted access to this info. Please submit a request to receive it.",
    RequestAccess: "Request Access",
    declined_reason: "Decline Reason: ",
    productUnavailable: "Sorry, this product is unavailable",
    youMightAlsoLike: "You might also like...",
    otherProducts: "Other products",
    total: "Total",
    addToCart: "Add to Cart",
    inCart: "In Cart",
    buildMixedPallet: "Build a Mixed Pallet",
    foundBetterPrice: "Found a better price?",
    LetUsKnow: "Let us know.",
    downloadSpecSheet: "Download Spec Sheet",
    compare: "Compare",
    reportIncorrectInformation: "Report Incorrect Information",
    addToPallet: "Add to Pallet",
    verifyEmail: "Verify Email",
    completeProfile: "Complete Profile",
    addToShoppingList: "Add to shopping list",
    removeFromShoppingList: "Remove from shopping list",
    details: "Details",
    noDataToShow: "No Data to Show",
    yourRequestSent: "Your request has been sent!",
    SignUpToSeeInformation:
      "Sign up for Epallet to see additional information.",
    SEO: {
      title: "E Pallet -",
      description: "Category page -",
    },
    messages: {
      generatingPDF: "Generating PDF file, please wait!",
      PDFDownloadedSuccessfully: "PDF downloaded successfully!",
      ProductAddedSuccessfully: "Product added to cart successfully!",
      productAddedToPallet: "Product added to pallet successfully.",
      signInOrSignUpToSeeDeliveredPrices:
        "Please sign-in or sign up to see delivered prices",
      CoolSchoolMessage:
        "Learn more about Cool School Cafe K12 foodservice loyalty program",
    },
    productDetails: {
      pricePerUnit: "Price per unit:",
      packSize: "Pack size:",
      leadTime: "Lead Time:",
      casesPerPallet: "Cases per pallet:",
      casesPerLayer: "Cases per layer:",
      ProductInformationProvided:
        "*Product information was provided by the manufacturer",
      publicLink: "public link",
      sendLinkByEmail: "Send Link by Email",
      AddRecipient: "Add Another Recipient",
      shareProduct: "Share Product",
      Form: {
        email: "email",
        yourEmail: "Your Email ",
        emailRequired: "Your email is required",
        invalidEmail: "Invalid email",
        name: "name",
        yourName: "Your Name ",
        nameRequired: "Your name is required",
        leastThreeCharacters: " Must be at least 3 characters",
        comments: "comments",
        commentsOptional: "Comments [optional]",
        recipientEmail: "recipient-email",
        recipientEmailSpace: "Recipient Email ",
        recipientEmailRequired: "Recipient email is required",
        recipientNameRequired: "Recipient name is required",
        inputValidEmail: "Please input a valid email.",
      },
    },
    priceDetails: {
      deliveredPrice: "Delivered Price",
      allPricesFreight: "All Prices Include Freight",
      perPallet: "Price per pallet",
      perCase: "Price per case",
      perUnit: "Price per unit",
      perLbs: "Price per lbs",
      perOz: "Price per oz",
      totalCases: "Total cases",
      quantity: "Quantity",
      addMoreAndSaveExtra: "Add more pallets and save.",
      minimumPalletQuantityItem:
        "Note: Please check your cart to see if you have met the vendor’s order minimum.",
      minimumPalletQuantityVendor:
        ". Please check your cart to see if it meets the vendor’s order minimum.",
      minimumPalletQuantity: "Note: The Minimum Layer Quantity ",
      minimumPalletNoteForVendor:
        "Please note that this vendor has a total order minimum. To determine if you have met the minimum requirements, please review the items in your cart.",
      minimumPalletMsg:
        "Note: When you choose to “Build a Mixed Pallet,” the minimum pallet quantity may be lower than a full pallet. Please check your cart to determine if this meets the vendor’s order minimum.",
    },
  },
  requestQuote: {
    requestQuoteTitle: "Get your quote",
    requestQuoteSubTitle: "Let us share how Move can unlock your logistics",
    submit: "Submit Request",
  },
  OrderConfirmation: {
    singleOrderNumberLabel: "Your order number is: ",
    orderNumberLabel: "Order Number: ",
    receivedYour: "We've received your ",
    haveForwardedVendors: "and have forwarded it to our vendors. ",
  },
  Cart: {
    notValidPrice: "Please change product quantity to see prices.",
    addingItem: "by adding this item!",
    youSaved: "You just saved",
    vendorPallets: "pallet(s) from this vendor.",
    vendorPalletsMultipleReqs: "pallet(s)",
    vendorCases: "case(s) from this vendor.",
    vendorCasesMultipleReqs: "case(s)",
    coupon: "Apply coupons and discounts in the Payment section in checkout.",
    redemptionText: "Redemption Value",
    breakDownCart: "BreakDown Cart",
    noOffers:
      "Sorry, this product doesn't have any offers right now. Please check another product.",
    palletsFromVendor: "pallet(s) from this vendor.",
    lbs: "lbs.",
    addMore: "to add more.",
    addMoreMultipleReqs: "to add more and meet one of these requirements:",
    clickHere: "Click here",
    clickHereMultipleReqs: "click here",
    minOrderQty:
      "Minimum order quantity has not been met for this vendor. Please change quantity above or add ",
    minOrderQtyCases:
      "Minimum order case quantity has not been met for this vendor. Please change quantity above or add ",
    minOrderRequirementsNotMet: 
      "Minimum order requirement(s) have not been met for this vendor. Please",
    minOrderWeight:
      "Minimum order weight has not been met for this vendor. Please add more products to increase the weight by ",
    toEditItems: "to edit these items",
    saveToCompleteLater:
      "These items will be saved and you can complete them later",
    viewCart: "View Cart",
    ItemsNotReadyToPurchase: "The following items are not ready to purchase",
    ItemNoLongerAvailable: "This item is no longer available",
    completeMixedPallet: "Complete This Mixed Pallet",
    palletCapacity: "Pallet Capacity",
    inYourCart: "in your cart",
    naturesWild: "Nature's Wild Organic",
    savedForLater: "Saved for later",
    back: "Back",
    noProductsInCart:
      "Your cart is empty. Browse our products and add items to your cart to",
    continueToShopping: "continue shopping.",
    deliveredPrice: "Delivered price: ",
    byAddingThisMixedPallet: "by adding this Mixed Pallet!",
    youJust: "You just",
    savedDollar: "saved $",
    couponAddedSuccessfully: "Coupon Added Successfully",
    couponRemovedSuccessfully: "Coupon Removed Successfully",
    enterValidCoupon: "Please enter a valid coupon code",
    orderSummary: "Order Summary",
    items: "Item(s):",
    grossAmount: "Gross amount:",
    tax: "Tax:",
    orderTotal: "Order Total:",
    myCart: "My Cart",
    totalDiscount: "Total Discount",
    apply: "Apply",
    proceedToCheckout: "Proceed to Checkout",
    DoNotHavePermissionsToPlaceOrders:
      "You don't have permissions to Place Orders",
    info: "Info",
    orderSummaryInfo:
      " All prices are subject to confirmation including manufacturer acceptance, delivery conditions and may incur additional fees if required conditions such as liftgate, driver assist, etc. have not been selected. To get the most accurate price, please select all conditions that best matches your location and requirements.",
    Card: {
      errorInCartItems: "Error: in get cart items",
      errorInCartApi: "Error: in move to cart api",
      errorInLaterApi: "Error: in saved for later api",
      errorChangeItemQuantity: "Error: change item quantity has an error",
      savedForLaterModal: "Saved For Later",
      itemAlreadyAdded: "This item is already added!",
      packSize: "Pack size:",
      leadTime: "Lead Time:",
      casesPerPallet: "Cases per pallet",
      priceCase: "Price/Case: ",
      perOz: "Price per oz:",
      qtyChanged: "Item's quantity changed successfully!",
      qtyZero: "Item has been deleted successfully!",
      movedToCart: "Item moved to cart successfully!",
      savedForLaterMsg: "Item saved for later!",
      mixAndMatchVolumeText: "Mix & match from this vendor for volume discount",
      mixAndMatchVolumeModalButtonText: "Proceed To Cart",
    },
    SEO: {
      title: "E Pallet - Cart",
      description: "Cart Page",
    },
  },
  Haggle: {
    joinHaggleRewards:"Join Haggle Rewards.",
    earnRewards:"Earn rewards effortlessly and enjoy the benefits—",
    agreeHaggle: "By checking this box, I agree to be part of Haggle Rewards.",
    createAccount:
      "Create an account earn rewards effortlessly and enjoy the benefits",
    haggleSign: 'By clicking "Sign Up", you are agreeing to our',
    receiveHaggle: "and to receive communications about Haggle Rewards.",
    currentTerms: "current Terms and Conditions",
    explore: "Explore all Haggle benefits",
    forFree: "for free!",
    noMembership: "No membership fee",
    freeHaggle: "Haggle Rewards is free – no hidden fees or costs.",
    spend: "Spend and unlock More",
    betterBenefits:
      "The more you spend with Epallet the better benefits you get.",
    enjoyBenefits: "Enjoy benefits for a year.",
    maintainBenefits: "Maintain your benefits for 12 months.",
    asHaggle: "as Haggle rewards",
    thankYou: "Thank you!",
    willBeOnTouch: "Our team will be in touch soon.",
    letUsShare:
      "Let us share how Haggle can quickly help you earn rewards to put towards your purchases.",
    submit: "Submit",
    saveMore: "Want to save more?",
    haggle: "Haggle Rewards",
    joinDate: "Join date",
    joinHaggle: "Join Haggle",
    Anniversary: "Anniversary",
    Tier: "Tier",
    availableRewards: "Available rewards",
    noHaggle: "You have no Haggle Rewards transactions",
  },
  Account: {
    defaultAddress:"Make this my default address",
    withoutHaggle:
      "Earn rewards on purchases, new items, and much more with Haggle.",
    join: "Join Now",
    noData: "No Data",
    learnMore: "Learn more",
    haggle: "Haggle Rewards",
    residential_delivery:
      "Any delivery area within a quarter mile of a residential neighborhood. Residential deliveries typically require smaller trucks due to street size. Additional services will also be required for a residential delivery such as an appointment and liftgate.",
    lift_gate_required:
      "A small lift attached to the back of carrier trucks that assists in lifting or lowering freight when there is no forklift or dock available to assist in unloading the freight.",
    inside_delivery_required:
      "When requested, the driver will bring the freight a few feet inside the main entrance. If conditions permit, the driver may move shipments or portions of shipments from or to positions beyond the immediately adjacent loading and unloading positions. Because of liability issues, a freight carrier cannot enter your house.",
    appointment_required:
      "When instructions or requests are made to Carrier to schedule an appointment or otherwise establish a date and/or specific time or window of time for the delivery of a shipment. Most carriers will have an allowance of one hour before/after the appointment time or window of time provided to deliver.",
    limited_access:
      "The delivery location is not a typical business location within a commercial area, or a location requiring special accommodations to complete the delivery. This could be a farm, church, strip mall, prison, storage unit, grocery warehouse, gated location, school, or any other location the driver cannot easily and efficiently access. Any location not open to the walk-in public during normal business hours will be limited access. This includes any place where it is difficult to maneuver a 53 ft. or 48 ft semi-truck.",
    saveAllToCart: "Add all Items to Cart",
    saveAllToWishList: "Add all Items to Shopping List",
    addToWishList: "Add to Shopping List",
    purchaseOrder: "Purchase Order",
    editButtonTooltip: "Please complete your profile to be able to edit",
    accountOverviewTitle: "Account Overview",
    updateButton: "Update",
    myOrdersTitle: "My Orders",
    viewAllButton: "View All",
    viewButton: "View",
    shoppingListsTitle: "Shopping Lists",
    shoppingList: "Shopping List",
    createNewShoppingList: "Create New Shopping List",
    shoppingListNotAvailableMessage: "No shopping lists available yet.",
    dashboardTitle: "Dashboard",
    attestMessage: " I attest that the products purchased are for resale only.",
    yesAnswer: "Yes",
    noAnswer: "No",
    shippingHoursQuestion: "What are the shipping hours for this facility?",
    residentialDeliveryRequired: "Residential delivery required",
    liftGateRequirements: "Lift Gate requirements",
    insideDeliveryRequired: "Inside delivery required",
    deliveryAppointmentRequired: "Delivery appointment required",
    limitedAccess: "Limited access",
    manageEmailPreferences: "Manage your email preferences",
    exclusiveDealsAndPromotions: "Exclusive deals and promotions",
    announcements: "Announcements",
    blogsAndArticles: "Blogs & Articles",
    cancelButton: "Cancel",
    saveButton: "Save",
    myAddressesTitle: "My Addresses",
    addressTitle: "Address",
    phoneTitle: "Phone",
    makeDefaultButton: "Make default",
    addAddressButton: "Add Address",
    cardNumber: "Card Number",
    discountValue: "2% Discount",
    addAccountButton: "Add Account",
    expiresDate: "Expires",
    addCardButton: "Add Card",
    cardBalance: "Credit Balance",
    paymentTerms: "Payment Terms",
    days: "days",
    availableCredit: "Available Credit",
    applyForCreditLine: "Apply for credit line",
    approveCreditLineMessage:
      "It may take 1 to 2 business days to approve your credit line.",
    achPayments: "ACH Payments",
    discountValueWithACHPayment:
      "Save 2% on your order with ACH payment option",
    paymentMethodsTitle: "Payment Methods",
    okButton: "OK",
    changePassword: "Change password",
    accountInformationTitle: "Account Information",
    permissions: "Permissions",
    manager: "Manager",
    admin: "Admin",
    manageOrders: "Manage Orders",
    viewOrders: "View Orders",
    manageShippingAddress: "Manage Shipping Address",
    viewShippingAddress: "View Shipping Address",
    manageUsers: "Manage Users",
    viewUsers: "View Users",
    viewDetails: "View Details",
    removeUserButton: "Remove User",
    resendInviteButton: "Resend invite",
    applyFilterButton: "Apply Filter",
    maxPriceValidateMessage: "Max price should be greater than min price",
    priceValidateMessage: "The price value must be positive",
    filtersButton: "Filters",
    backToTrackOrders: "Back to Track Orders",
    orderId: "Order ID",
    status: "Status",
    placedBy: "Placed by",
    OrderDate: "Order Date",
    amount: "Amount",
    paymentMethod: "Payment method",
    quantity: "Quantity",
    deliveryAddress: "Delivery Address",
    reorderButton: "Reorder",
    pdf: "PDF",
    deliveredPrice: "Delivered price: $",
    perCase: "Price per case: ",
    cases: "Cases",
    selectAll: "Select All",
    addToCart: "Add to Cart",
    back: "Back",
    emptyShoppingListMessage: "There are no products in your shopping list",
    removeItemsConfirmation:
      "Are you sure you want to remove selected items from shopping list?",
    setAsDefault: "Set as default",
    trackOrders: "trackOrders",
    trackOrder: "trackOrder",
    shipped: "Shipped",
    casesPerLayer: "Cases per layer",
    viewedItems: "Viewed Items",
    itemsPerPage: "Items per page:",
    completingProfileMessage:
      "Completing your profile will give you access to special pricing for your business type. We will also be able to make sure you receive the most accurate pricing to your specific address.",
    myAccountTitle: "My Account",
    messages: {
      canNotUpdateProfile:
        "An error has occurred while updating your profile, please try again",
    },
    visitVendorDashboard: "Visit Vendor Dashboard",
    SEO: {
      title: "E Pallet - My Account",
      description: "My Account Page",
      accountInfo: {
        title: "E Pallet - Account Information",
        description: "Account Information Page",
      },
      manageUsers: {
        title: "E Pallet - Manage Users",
        description: "Manage Users Page",
      },
      myOrders: {
        title: "E Pallet - My Orders",
        description: "My Orders Page",
      },
      shoppingList: {
        title: "E Pallet - Shopping Lists",
        description: "Shopping Lists Page",
      },
      dashboard: {
        title: "E Pallet - Dashboard",
        description: "Shopping Lists Page",
      },
      viewedItems: {
        title: "E Pallet - Viewed Items",
        description: "Viewed Items Page",
      },
    },
    AddressForm: {
      defaultAddress: "Default Address",
      setAsDefault: " Set as default",
      shippingHours: "What are the shipping hours for this facility?",
      residentialDeliveryRequired: "Residential delivery required",
      noDeliveryRequirements: "No delivery requirements",
      liftGateRequirements: "Lift Gate requirements",
      insideDeliveryRequired: "Inside delivery required",
      deliveryAppointmentRequired: "Delivery appointment required",
      limitedAccess: "Limited access",
      save: "Save",
      update: "Update",
      addNew: "Add New",
      edit: "Edit",
      selectLimitedAccess: "Show additional accessorial information",
      deliveryModalTitle: "Please confirm if you have any special delivery requirements.",
      deliveryModalContent: "If none are specified but required at the time of delivery, additional fees may apply and will be your responsibility.",
      limitedAccessModal: {
        govt_airport_mine_gas_oil_delivery:
          "Govt Airport Mine Gas Oil Delivery",
        church: "Church",
        commercial: "Commercial",
        hospital: "Hospital",
        jobsite: "Jobsite",
        self_storage: "Self-Storage",
        plants: "Plants",
        residential: "Residential",
        schools: "Schools",
        food_banks: "Food Banks",
        prison_jails: "Prison & Jails",
        sports_arenas: "Sports Arenas",
        convention_center: "Convention Center",
        office_retail: "Office & Retail",
        trade_show_site: "Trade Show Site",
      },
    },
    BillingAddressForm: {
      title: "Edit Billing Address",
      messages: {
        billingAddressUpdated:
          "Your billing address has been successfully updated!",
      },
    },
  },
  Search: {
    trendingSearch: "Trending Search",
    searchResults: "Search Results",
    history: "History",
    recentSearch: "Recent Search",
    canNotFindAProduct: "Can’t find a product?",
    tryCheckingYourSpelling:
      "Try checking your spelling or use more general terms",
    requestIt: "Request it!",
    SEO: {
      title: "E Pallet - Search",
      description: "E Pallet - Search page",
    },
  },
  MixedPallet: {
    home: "Home",
    productNoLongerAvailable: "Sorry, this product is no longer available",
    quantityNotAvailable: "Sorry, this quantity is not available at this moment. Max qty: ",
    mixedPallet: "Mixed Pallet",
    minimumPalletQuantity: "Note: The Minimum Quantity ",
    caseQuantity: "Case Quantity",
    palletQuantity: "Pallet Quantity",
    buildYourMixedPallet: "Build your mixed pallet from ",
    applyFilterButton: "Apply Filter",
    dropHere: "Drop Here ...",
    dragAndDropHere: "Drag and Drop Here",
    keepAdding: "Keep adding. Drag and drop here.",
    calculatePriceButton: "Calculate Price",
    calculatePriceActionAvailabilityMessage:
      "Calculate Price action will become active once the pallet capacity reaches a certain threshold. Keep adding layers to your pallet until the calculate price button turns on!",
    palletCapacity: "Pallet Capacity",
    orderTotal: "Order Total:",
    addMoreLayersToSaveMony: "Add more layers to save even more money!",
    removeConfirmationMessage: "Are you sure you want to remove",
    SuccessfullyDeleted: "Product Removed From Pallet Successfully",
    fromPallet: "from pallet",
    signIn: "sign in",
    or: "or",
    signUp: "sign up",
    toBeAbleToDragAndDrop: "to be able to drag and drop the ",
    itemsToCreateYourMixedPalletLayers:
      "items to create your mixed pallet layers.",
    please: "Please",
    clearButton: "Clear",
    dragAndDrop: " Drag and drop the ",
    backToProductList: "Back to product list",
    noDataToShow: "No Data to Show",
    loading: "loading...",
    SEO: {
      title: "E Pallet - Choose a vendor",
      description: "Choose a vendor | ePallet",
    },
  },
  MixedPalletVendor: {
    SEO: {
      title: "E Pallet - Build your own mixed pallet",
      description: "Build your own mixed pallet | ePallet",
    },
  },
  Compare: {
    noDataAvailable: "No data available.",
    addProduct: "Add Product",
    deliveredPrice: "Delivered price:",
    priceNotAvailable: "Price not available",
    compareItems: "Compare Items",
    compareProduct: "Compare Product",
    SEO: {
      title: "E Pallet - Compare",
      description: "Compare Page",
    },
    days: "days",
  },
  SignUp: {
    signUp: "Sign Up",
    signUpAsCustomer: "Sign Up as a Customer",
    signUpAsVendor: "Sign Up as a Vendor",
    registerNowToAccessALL:
      " Register now to access all the savings available on all your favorite brands!",
    ClickThisBoxToReceiveEPallet:
      "Click this box to receive ePallet newsletters and promotional marketing materials.",
    alreadyHaveAccount: "Already have an account?",
    welcomeToEPallet: "Welcome to ePallet",
    emailHasBeenSent: "An e-mail has been sent to",
    validateYourEmailAddressAsCustomer:
      ". Please validate your e-mail address to be able to log in as a customer. If you have not received an e-mail link, please click below.",
    validateYourEmailAddressAsVendor:
      ". Please validate your e-mail address to be able to log in as a vendor. If you have not received an e-mail link, please click below.",
    SEO: {
      title: "E Pallet - Sign Up",
      description: "Sign Up Page",
    },
    signIn: "Sign in",
  },
  Login: {
    backTo: "Back to",
    SignUp: "Sign up",
    DoNotHaveAnAccount: "Don't have an account?",
    SEO: {
      title: "E Pallet - Login",
      description: "Login Page",
    },
    resetPassword: "Reset Password",
    signIn: "Sign In",
    messages: {
      passwordResetLinkSend:
        "A password reset link has been send to your email ",
      unableToSignIn: "Unable to sign in, please check your credentials",
      emailUnRegistered:
        "Couldn't find your email, please register or enter a valid email.",
    },
  },
  Wishlist: {
    SEO: {
      title: "E Pallet - Wishlist",
      description: "Wishlist Page",
    },
    AddToShoppingList: {
      createNewShoppingList: "Create New Shopping List",
      save: "Save",
      manageYourShoppingLists: " Manage Your Shopping Lists",
      noShoppingListsAvailable:
        "No Shopping lists available, please add new one.",
    },
  },
  GeneralInformation: {
    information: "Information",
    bySigning: "By signing up, you agree to Epallet",
    generalCompanyInformation: "General company information",
    howDidYouHearAboutUs:"How did you hear about us?",
    completeYourProfile:
      " Complete your profile for a seamless experience customized to your business type.",
    completeYourProfileMobile:
      "Get more and register to get the best wholesale pricing and product selection",
    attestMessage: " I attest that the products purchased are for resale only.",
    yesAnswer: "Yes",
    noAnswer: "No",
    deliveryAddress: "Delivery address",
    enterYourDeliveryDetails:
      "Enter your delivery details to access special pricing accurate to your address.",
    enterYourDeliveryDetailsMobile:
      "Energistically expedite one-to-one human capital before highly efficient applications. Proactively engineer 2.0 schemas before collaborative mindshare. Competently ",
    saveAndContinue: "Save & Continue",
    congratulations: "Congratulations!",
    completedProfileMessage: "You have completed your profile.",
    updateOrChangeInformation: "To update or change information, visit",
    applyForCreditLine: "To apply for a creditline and learn more, visit",
    continueShopping: "Continue Shopping",
  },
  Footer: {
    safeAnd: "Safe and",
    secureCheckout: "Secure Checkout",
    greatCustomerService: "Great Customer Service",
    bestPriceGuaranteed: "Best Price Guaranteed",
    HassleDelivery: "Hassle-free Delivery Coordination",
    RealTimePricing: "Real-time pricing",
    rating: "Rating",
    Explore: "Explore",
    HelpCenter: "Help Center",
    MyAccount: "My Account",
    NewsAndOffers: "News, offers, and more",
  },
  Header: {
    inactiveProductMessage: "Show your inactive products",
    name: "Name",
    state: "State",
    zipCode: "Zip Code",
    addNewLocation: "Add new location",
    orderBy: "Order by",
    changeDeliveryZipCodeMessage:
      "To see prices for other zip code, change your delivery zip code",
    viewAll: "View All",
    MiniCart: {
      totalCases: "Total cases:",
      total: "Total",
      cases: "Cases:",
    },
  },
  ExpirationModal: {
    title: "Your session has expired. Please sign-in again.",
    cancelText: "Back to home page",
    okText: "Sign-in again",
    content: "Your session has expired, please sign-in again.",
  },
  
};

export default englishContent;
