import Icon from "@components/shared/Icon";
import styles from "../styles.module.scss";
import { AddProductsProps } from "./types";
import { useContext, useState, useEffect } from "react";
import { AppContext } from "~/src/libs/context";

const AddProduct = ({ viewType }: AddProductsProps) => {
  const { compareProductsSku } = useContext<any>(AppContext);
  const [addMessage, setAddMessage] = useState("");
  const [mobileView, setMobileView] = useState<boolean>();

  useEffect(() => {
    const current = 3 - compareProductsSku?.length;
    if (current === 1) {
      setAddMessage("Add one more product");
    } else if (current === 2) {
      setAddMessage("Add two more products");
    }
    if (viewType === "mobileView") {
      setMobileView(true);
    }
  }, [compareProductsSku]);
  return (
    <>
      {!mobileView ? (
        <>
          <div className={`${styles.addProductContainer}`}>
            <span className="font-semibold-base lh-sm text-oxfordBlue">
              {addMessage}
            </span>
          </div>
          <div
            className={`${styles.mobileAddProductContainer} ${styles.panelContent} `}
          >
            <div className={`${styles.addIconWrapper}`}>
              <Icon name="add" height={15} width={15} color="#fff" />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={`${styles.MobileAddProductContainer}`}>
            <div className={`${styles.addIconWrapper} p-6 `}>
              <Icon name="add" height={15} width={15} color="#fff" />
            </div>
            <span className="font-semibold-base lh-sm ms-4 text-oxfordBlue">
              {addMessage}
            </span>
          </div>
        </>
      )}
    </>
  );
};

export default AddProduct;
