var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ovkwrf_mccAo-c4scNf8p"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
console.log("Initializing Sentry on client...");
const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

const isProd = process.env.NODE_ENV === 'production';


// Global event timing control
let lastEventSentAt = 0;
const MIN_TIME_BETWEEN_EVENTS = 30000; // 30 seconds

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.005,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: !isProd,
  beforeSend(event, hint) {
    const now = Date.now();
    if (now - lastEventSentAt < MIN_TIME_BETWEEN_EVENTS) {
      return null; // Drop the event if too soon after the last one
    }

    lastEventSentAt = now;

    if (event.level === 'info' || event.level === 'warning') {
      return null;
    }

    return event;
  },
  
});
console.log("Sentry initialized on client");
