import { Col, Radio, RadioChangeEvent, Row, Tooltip, Modal } from "antd";
import { useState, useEffect } from "react";
import Button from "~/src/components/shared/Button";
import Icon from "~/src/components/shared/Icon";
import Tags from "~/src/components/shared/Tag";
import CreditCardForm from "~/src/components/shared/CreditCardForm";
import DataLoader from "~/src/components/shared/DataLoader";
import { PaymentIcon } from "react-svg-credit-card-payment-icons";
import styles from "./styles.module.scss";
import CreditCardType from "../CreditCardType";
import { getText } from "~/src/libs/resources";

const CreditCards = ({
  creditCards,
  addCreditCard,
  deleteCreditCard,
  setCardAsDefault,
  defaultCard,
  loading,
}: {
  creditCards: CreditCardType[];
  defaultCard: CreditCardType;
  addCreditCard: Function;
  deleteCreditCard: Function;
  setCardAsDefault: Function;
  loading: boolean;
}) => {
  const { confirm } = Modal;

  const [showAddNewCardForm, setShowAddNewCardForm] = useState(false);
  const [loadingState, setLoadingState] = useState(loading);

  useEffect(() => {
    setShowAddNewCardForm(false);
  }, [creditCards]);

  const onChange = (e: RadioChangeEvent) => {
    setCardAsDefault(e.target.value);
  };

  const showDeleteConfirm = (cardId: number) => {
    document.body.style.overflow = "hidden";
    confirm({
      title: "Are you sure to delete this Credit Card?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteCreditCard(cardId);
        document.body.style.overflow = "auto";
      },
      onCancel() {
        document.body.style.overflow = "auto";
      },
    });
  };

  return (
    <div className={styles.creditCards}>
      {loadingState && (
        <DataLoader className={`${styles.loader} py-5`} size="large" />
      )}
      <div
        className={`${styles.cardsWrapper} ${
          loadingState && styles.cardsLoadingWrapper
        }`}
      >
        <Radio.Group
          className={styles.buttonGroup}
          onChange={onChange}
          value={defaultCard?.id}
        >
          <Row gutter={[16, 16]}>
            {creditCards?.map((item: CreditCardType) => (
              <Col
                key={item?.id}
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 8 }}
                xxl={{ span: 6 }}
              >
                <div className={styles.itemBox}>
                  <div className={styles.titleContainer}>
                    <PaymentIcon
                      type={item?.card_type}
                      width={40}
                      format="flatRounded"
                    />

                    {item?.is_default ? (
                      <Radio value={item?.id}></Radio>
                    ) : (
                      <Tooltip title="Make default" placement="bottom">
                        <Radio value={item?.id}></Radio>
                      </Tooltip>
                    )}
                  </div>
                  <h3 className="font-bold-base mb-5">{item?.card_type}</h3>

                  <div className="pb-4">
                    <h3 className="font-medium-xs mb-0">
                      {getText()?.Account?.cardNumber}
                    </h3>
                    <span className="font-medium-xs">
                      XXXX XXXX XXXX {item?.four_digits}
                    </span>
                  </div>
                  <div className="pb-6">
                    <h3 className="font-bold-xs mb-0">
                      {getText()?.Account?.expiresDate}
                    </h3>
                    <span className="font-medium-xs mb-0">
                      {item?.month}/{item?.year}
                    </span>
                  </div>
                  <div className={styles.lastRow}>
                    <div className={styles.icons}>
                      <Tooltip  title="Delete" placement="bottom">
                        <button className="p-0">
                          <Icon
                            name="delete"
                            width={15}
                            height={15}
                            onClick={() => showDeleteConfirm(item?.id)}
                          />
                        </button>
                      </Tooltip>
                    </div>
                    {item?.is_default ? (
                      <Tags theme="light" text="Default" size="sm" />
                    ) : (
                      <a
                        className={`m-0 ${styles.makeDefault}`}
                        onClick={() => setCardAsDefault(item?.id)}
                      >
                        <h6 className="m-0 font-semibold-xs">
                          {getText()?.Account?.makeDefaultButton}
                        </h6>
                      </a>
                    )}
                  </div>
                </div>
              </Col>
            ))}
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 8 }}
              xxl={{ span: 6 }}
            >
              {showAddNewCardForm ? (
                <div className={styles.creditCardFormContainer}>
                  <CreditCardForm
                    onCancel={() => setShowAddNewCardForm(false)}
                    onAddCreditCard={addCreditCard}
                    setLoadingState={(value: boolean) => setLoadingState(value)}
                  />
                </div>
              ) : (
                <Button
                  onClick={() => setShowAddNewCardForm(true)}
                  className={styles.addButton}
                  theme="secondary"
                >
                  <div className={styles.icons}>
                    <Icon name="add" width={40} height={40} color="#ffffff" />
                  </div>
                  <div className="pt-5">
                    {getText()?.Account?.addCardButton}
                  </div>
                </Button>
              )}
            </Col>
          </Row>
        </Radio.Group>
      </div>
    </div>
  );
};
export default CreditCards;
